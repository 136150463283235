import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  customer: [
    {
      id: 1,
      date: '2020-11-18',
      saleCode: 'TB001/2021/00000000001',
      ref: 'jago-022129',
      biller: 'Kasir 01',
      saleStatus: 'Draft',
      customer: 'Munib',
      subtotal: '1000000',
      disc: '25000',
      ship: '50000',
      tax: '0',
      typePayment: 'CASH',
      paymentStatus: 'LUNAS',
    },
    {
      id: 2,
      date: '2020-11-18',
      saleCode: 'TB001/2021/00000000001',
      ref: 'jago-022129',
      biller: 'Kasir 01',
      saleStatus: 'Draft',
      customer: 'Munib',
      subtotal: '1000000',
      disc: '25000',
      ship: '50000',
      tax: '0',
      typePayment: 'CASH',
      paymentStatus: 'LUNAS',
    },
    {
      id: 3,
      date: '2020-11-18',
      saleCode: 'TB001/2021/00000000001',
      ref: 'jago-022129',
      biller: 'Kasir 01',
      saleStatus: 'Draft',
      customer: 'Munib',
      subtotal: '1000000',
      disc: '25000',
      ship: '50000',
      tax: '0',
      typePayment: 'CASH',
      paymentStatus: 'LUNAS',
    },
    {
      id: 4,
      date: '2020-11-18',
      saleCode: 'TB001/2021/00000000001',
      ref: 'jago-022129',
      biller: 'Kasir 01',
      saleStatus: 'Draft',
      customer: 'Munib',
      subtotal: '1000000',
      disc: '25000',
      ship: '50000',
      tax: '0',
      typePayment: 'CASH',
      paymentStatus: 'LUNAS',
    },
    {
      id: 5,
      date: '2020-11-18',
      saleCode: 'TB001/2021/00000000001',
      ref: 'jago-022129',
      biller: 'Kasir 01',
      saleStatus: 'Draft',
      customer: 'Munib',
      subtotal: '1000000',
      disc: '25000',
      ship: '50000',
      tax: '0',
      typePayment: 'CASH',
      paymentStatus: 'LUNAS',
    },
    {
      id: 6,
      date: '2020-11-18',
      saleCode: 'TB001/2021/00000000001',
      ref: 'jago-022129',
      biller: 'Kasir 01',
      saleStatus: 'Draft',
      customer: 'Munib',
      subtotal: '1000000',
      disc: '25000',
      ship: '50000',
      tax: '0',
      typePayment: 'CASH',
      paymentStatus: 'LUNAS',
    },
    {
      id: 7,
      date: '2020-11-18',
      saleCode: 'TB001/2021/00000000001',
      ref: 'jago-022129',
      biller: 'Kasir 01',
      saleStatus: 'Draft',
      customer: 'Munib',
      subtotal: '1000000',
      disc: '25000',
      ship: '50000',
      tax: '0',
      typePayment: 'CASH',
      paymentStatus: 'LUNAS',
    },
    {
      id: 8,
      date: '2020-11-18',
      saleCode: 'TB001/2021/00000000001',
      ref: 'jago-022129',
      biller: 'Kasir 01',
      saleStatus: 'Draft',
      customer: 'Munib',
      subtotal: '1000000',
      disc: '25000',
      ship: '50000',
      tax: '0',
      typePayment: 'KREDIT',
      paymentStatus: 'UTANG',
    },
    {
      id: 9,
      date: '2020-11-18',
      saleCode: 'TB001/2021/00000000001',
      ref: 'jago-022129',
      biller: 'Kasir 01',
      saleStatus: 'Draft',
      customer: 'Munib',
      subtotal: '1000000',
      disc: '25000',
      ship: '50000',
      tax: '0',
      typePayment: 'KREDIT',
      paymentStatus: 'UTANG',
    },
    {
      id: 10,
      date: '2020-11-18',
      saleCode: 'TB001/2021/00000000001',
      ref: 'jago-022129',
      biller: 'Kasir 01',
      saleStatus: 'Draft',
      customer: 'Munib',
      subtotal: '1000000',
      disc: '25000',
      ship: '50000',
      tax: '0',
      typePayment: 'KREDIT',
      paymentStatus: 'LUNAS',
    },
    {
      id: '53922ae4305e48aa941999a0362b45b5',
      date: '2020-11-18',
      saleCode: 'TB001/2021/00000000001',
      ref: 'jago-022129',
      biller: 'Kasir 01',
      saleStatus: 'Draft',
      customer: 'Munib',
      subtotal: '1000000',
      disc: '25000',
      ship: '50000',
      tax: '0',
      typePayment: 'KREDIT',
      paymentStatus: 'LUNAS',
    },
  ],
  /* eslint-disable global-require */
}

mock.onGet('/app-data/transDetail').reply(() => [200, data.customer])
