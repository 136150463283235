import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  customer: [
    {
      id: 1,
      saleCode: '0452297501931931',
      customer: 'Cust. 001',
      refCode: '11110278911',
      subTotal: 1000000,
      diskon: 0,
      pajak: 0,
      ongkir: 0,
      typeBayar: 'KREDIT',
      status: 'BELUM_LUNAS',
    },
    {
      id: 2,
      saleCode: '0452297501931931',
      customer: 'Cust. 001',
      refCode: '11110278911',
      subTotal: 1000000,
      diskon: 0,
      pajak: 0,
      ongkir: 0,
      typeBayar: 'KREDIT',
      status: 'BELUM_LUNAS',
    },
    {
      id: 3,
      saleCode: '0452297501931931',
      customer: 'Cust. 001',
      refCode: '11110278911',
      subTotal: 1000000,
      diskon: 0,
      pajak: 0,
      ongkir: 0,
      typeBayar: 'CASH',
      status: 'LUNAS',
    },
    {
      id: 4,
      saleCode: '0452297501931931',
      customer: 'Cust. 001',
      refCode: '11110278911',
      subTotal: 1000000,
      diskon: 0,
      pajak: 0,
      ongkir: 0,
      typeBayar: 'CASH',
      status: 'LUNAS',
    },
    {
      id: 5,
      saleCode: '0452297501931931',
      customer: 'Cust. 001',
      refCode: '11110278911',
      subTotal: 1000000,
      diskon: 0,
      pajak: 0,
      ongkir: 0,
      typeBayar: 'KREDIT',
      status: 'BELUM_LUNAS',
    },
    {
      id: 6,
      saleCode: '0452297501931931',
      customer: 'Cust. 001',
      refCode: '11110278911',
      subTotal: 1000000,
      diskon: 0,
      pajak: 0,
      ongkir: 0,
      typeBayar: 'CASH',
      status: 'LUNAS',
    },
    {
      id: 7,
      saleCode: '0452297501931931',
      customer: 'Cust. 001',
      refCode: '11110278911',
      subTotal: 1000000,
      diskon: 0,
      pajak: 0,
      ongkir: 0,
      typeBayar: 'CASH',
      status: 'LUNAS',
    },
    {
      id: 8,
      saleCode: '0452297501931931',
      customer: 'Cust. 001',
      refCode: '11110278911',
      subTotal: 1000000,
      diskon: 0,
      pajak: 0,
      ongkir: 0,
      typeBayar: 'KREDIT',
      status: 'BELUM_LUNAS',
    },
    {
      id: 9,
      saleCode: '0452297501931931',
      customer: 'Cust. 001',
      refCode: '11110278911',
      subTotal: 1000000,
      diskon: 0,
      pajak: 0,
      ongkir: 0,
      typeBayar: 'KREDIT',
      status: 'BELUM_LUNAS',
    },
    {
      id: 10,
      saleCode: '0452297501931931',
      customer: 'Cust. 001',
      refCode: '11110278911',
      subTotal: 1000000,
      diskon: 0,
      pajak: 0,
      ongkir: 0,
      typeBayar: 'KREDIT',
      status: 'BELUM_LUNAS',
    },
  ],
  /* eslint-disable global-require */
}

mock.onGet('/app-data/customerTrans').reply(() => [200, data.customer])
