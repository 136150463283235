import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  category: [
    {
      id: 1,
      codeCat: '20201118-06143',
      categoryImage: '-',
      categoryName: 'Semen',
      categoryParent: '-',
      startDate: '09/23/2016',
      price: '$23896.35',
      status: 'Active',
    },
    {
      id: 2,
      codeCat: '20201118-06133',
      categoryImage: '-',
      categoryName: 'Semen Putih',
      categoryParent: 'Semen',
      startDate: '05/20/2018',
      price: '$13633.69',
      status: 'Nonactive',
    },
    {
      id: 3,
      codeCat: '20201118-07881',
      categoryImage: '-',
      categoryName: 'Alat Bangunan',
      categoryParent: 'Peralatan',
      startDate: '03/24/2018',
      price: '$13076.28',
      status: 'Nonactive',
    },
    {
      id: 4,
      codeCat: '20201118-08231',
      categoryImage: '-',
      categoryName: 'Elektronik',
      categoryParent: '-',
      startDate: '12/03/2017',
      price: '$12336.17',
      status: 'Active',
    },
    {
      id: 5,
      codeCat: '20201118-12892',
      categoryImage: '-',
      categoryName: 'Besi',
      categoryParent: '-',
      startDate: '08/25/2017',
      price: '$10909.52',
      status: 'Active',
    },
    {
      id: 6,
      codeCat: '20201118-46782',
      categoryImage: '-',
      categoryName: 'Kayu',
      categoryParent: '-',
      startDate: '06/01/2017',
      price: '$17803.8',
      status: 'Active',
    },
    {
      id: 7,
      codeCat: '20201118-023231',
      categoryImage: '-',
      categoryName: 'Meubel',
      categoryParent: 'Kayu',
      startDate: '10/15/2017',
      price: '$18991.67',
      status: 'Active',
    },
    {
      id: 8,
      codeCat: '20201118-091182',
      categoryImage: '-',
      categoryName: 'Campuran Semen',
      categoryParent: 'Semen',
      startDate: '11/05/2016',
      price: '$19230.13',
      status: 'Active',
    },
    {
      id: 9,
      codeCat: '20201118-091183',
      categoryImage: '-',
      categoryName: 'Listrik',
      categoryParent: '-',
      startDate: '12/29/2018',
      price: '$11677.32',
      status: 'Active',
    },
    {
      id: 10,
      codeCat: '20201118-46782',
      categoryImage: '-',
      categoryName: 'Peralatan',
      categoryParent: '-',
      startDate: '04/19/2019',
      price: '$15939.52',
      status: 'Active',
    },
  ],
  products: [
    {
      id: 1,
      codeProd: '20201118-06143',
      productImage: '-',
      productName: 'Martil',
      category: 'Semen',
      brand: 'Brand 01',
      price: '$23896.35',
      sellPrice: '$23896.35',
      unit: 'EACH',
    },
    {
      id: 2,
      codeProd: '20201118-06133',
      productImage: '-',
      productName: 'Paku',
      category: 'Besi',
      brand: 'Brand 02',
      price: '$13633.69',
      sellPrice: '$13633.69',
      unit: 'PACK',
    },
    {
      id: 3,
      codeProd: '20201118-07881',
      productImage: '-',
      productName: 'Gergaji',
      category: 'Alat Bangunan',
      brand: 'Brand 03',
      price: '$13076.28',
      sellPrice: '$13076.28',
      unit: 'EACH',
    },
    {
      id: 4,
      codeProd: '20201118-08231',
      productImage: '-',
      productName: 'Sekop',
      category: 'Elektronik',
      brand: 'Brand 04',
      price: '$12336.17',
      sellPrice: '$12336.17',
      unit: 'EACH',
    },
    {
      id: 5,
      codeProd: '20201118-12892',
      productImage: '-',
      productName: 'Galvalum',
      category: 'Besi',
      brand: 'Brand 05',
      price: '$10909.52',
      sellPrice: '$10909.52',
      unit: 'EACH',
    },
    {
      id: 6,
      codeProd: '20201118-46782',
      productImage: '-',
      productName: 'Pipa PVC',
      category: 'Kayu',
      brand: 'Brand 06',
      price: '$17803.8',
      sellPrice: '$17803.8',
      unit: 'PCS',
    },
    {
      id: 7,
      codeProd: '20201118-023231',
      productImage: '-',
      productName: 'Kuas',
      category: 'Meubel',
      brand: 'Brand 07',
      price: '$18991.67',
      sellPrice: '$18991.67',
      unit: 'PCS',
    },
    {
      id: 8,
      codeProd: '20201118-091182',
      productImage: '-',
      productName: 'Semen Putih 50 Kg',
      category: 'Campuran Semen',
      brand: 'Brand 08',
      price: '$19230.13',
      sellPrice: '$19230.13',
      unit: 'SAK',
    },
    {
      id: 9,
      codeProd: '20201118-091183',
      productImage: '-',
      productName: 'Semen Gresik 50 Kg',
      category: 'Semen',
      brand: 'Brand 09',
      price: '$11677.32',
      sellPrice: '$11677.32',
      unit: 'SAK',
    },
    {
      id: 10,
      codeProd: '20201118-46782',
      productImage: '-',
      productName: 'Semen Gresik 40 Kg',
      category: 'Semen',
      brand: 'Brand 10',
      price: '$15939.52',
      sellPrice: '$11677.32',
      unit: 'SAK',
    },
  ],
  purchases: [
    {
      id: 1,
      date: '2020/11/18',
      reference: 'pr-06143',
      supplier: 'Apple.inc',
      total: '33600',
      paid: '33000',
      due: '238.35',
      purchaseStatus: 'Received',
      paymentStatus: 'Completed',
    },
    {
      id: 2,
      date: '2020/12/08',
      reference: 'pr-06154',
      supplier: 'Apple.inc',
      total: '33600',
      paid: '33000',
      due: '238.35',
      purchaseStatus: 'Received',
      paymentStatus: 'Completed',
    },
    {
      id: 3,
      date: '2020/10/29',
      reference: 'pr-06178',
      supplier: 'Abdullah',
      total: '33600',
      paid: '33000',
      due: '238.35',
      purchaseStatus: 'Received',
      paymentStatus: 'Due',
    },
    {
      id: 4,
      date: '2020/03/03',
      reference: 'pr-06183',
      supplier: 'Jan',
      total: '33600',
      paid: '33000',
      due: '238.35',
      purchaseStatus: 'Received',
      paymentStatus: 'Due',
    },
    {
      id: 5,
      date: '2020/07/18',
      reference: 'pr-06102',
      supplier: 'Jan',
      total: '33600',
      paid: '33000',
      due: '238.35',
      purchaseStatus: 'Pending',
      paymentStatus: 'Due',
    },
    {
      id: 6,
      date: '2020/11/6',
      reference: 'pr-06142',
      supplier: 'Toko Baru',
      total: '33600',
      paid: '33000',
      due: '238.35',
      purchaseStatus: 'Pending',
      paymentStatus: 'Due',
    },
    {
      id: 7,
      date: '2020/11/18',
      reference: 'pr-06141',
      supplier: 'Apple.inc',
      total: '33600',
      paid: '33000',
      due: '238.35',
      purchaseStatus: 'Received',
      paymentStatus: 'Completed',
    },
    {
      id: 8,
      date: '2020/01/11',
      reference: 'pr-06133',
      supplier: 'Samsung',
      total: '33600',
      paid: '33000',
      due: '238.35',
      purchaseStatus: 'Pending',
      paymentStatus: 'Due',
    },
    {
      id: 9,
      date: '2020/12/19',
      reference: 'pr-06122',
      supplier: 'Abdullah',
      total: '33600',
      paid: '33000',
      due: '238.35',
      purchaseStatus: 'Received',
      paymentStatus: 'Due',
    },
    {
      id: 10,
      date: '2020/01/8',
      reference: 'pr-06191',
      supplier: 'Abdullah',
      total: '33600',
      paid: '33000',
      due: '238.35',
      purchaseStatus: 'Pending',
      paymentStatus: 'Due',
    },
  ],
  /* eslint-disable global-require */
}

mock.onGet('/app-data/category').reply(() => [200, data.category])
mock.onGet('/app-data/products').reply(() => [200, data.products])
mock.onGet('/app-data/purchases').reply(() => [200, data.purchases])
