import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  customer: [
    {
      id: 1,
      kodePenjualan: '0452297501931931',
      customer: 'Cust. 0001',
      refCode: '11110278911',
      subTotal: '1000000',
      diskon: '0',
      pajak: '0',
      ongkir: '0',
      status: 'Pending',
    },
    {
      id: 2,
      kodePenjualan: '0452297501931932',
      customer: 'Cust. 0002',
      refCode: '-',
      subTotal: '2500000',
      diskon: '0',
      pajak: '0',
      ongkir: '0',
      status: 'Pending',
    },
    {
      id: 3,
      kodePenjualan: '0452297501931933',
      customer: 'Non-Cust',
      refCode: '-',
      subTotal: '5000000',
      diskon: '0',
      pajak: '0',
      ongkir: '0',
      status: 'Pending',
    },
    {
      id: 4,
      kodePenjualan: '0452297501931934',
      customer: 'Non-Cust',
      refCode: '11110278911',
      subTotal: '7850000',
      diskon: '0',
      pajak: '0',
      ongkir: '0',
      status: 'Pending',
    },
    {
      id: 5,
      kodePenjualan: '0452297501931935',
      customer: 'Cust. 0005',
      refCode: '11110278911',
      subTotal: '1000000',
      diskon: '0',
      pajak: '0',
      ongkir: '0',
      status: 'Pending',
    },
    {
      id: 6,
      kodePenjualan: '0452297501931936',
      customer: 'Cust. 0006',
      refCode: '11110278911',
      subTotal: '7000000',
      diskon: '0',
      pajak: '0',
      ongkir: '0',
      status: 'Pending',
    },
    {
      id: 7,
      kodePenjualan: '0452297501931937',
      customer: 'Cust. 0007',
      refCode: '-',
      subTotal: '8900000',
      diskon: '0',
      pajak: '0',
      ongkir: '0',
      status: 'Pending',
    },
    {
      id: 8,
      kodePenjualan: '0452297501931938',
      customer: 'Cust. 0008',
      refCode: '-',
      subTotal: '2200000',
      diskon: '0',
      pajak: '0',
      ongkir: '0',
      status: 'Pending',
    },
    {
      id: 9,
      kodePenjualan: '0452297501931939',
      customer: 'Cust. 0009',
      refCode: '-',
      subTotal: '1800000',
      diskon: '0',
      pajak: '0',
      ongkir: '0',
      status: 'Pending',
    },
    {
      id: 10,
      kodePenjualan: '0452297501931940',
      customer: 'Cust. 0010',
      refCode: '11110278911',
      subTotal: '500000',
      diskon: '0',
      pajak: '0',
      ongkir: '0',
      status: 'Pending',
    },
  ],
  /* eslint-disable global-require */
}

mock.onGet('/app-data/transLog').reply(() => [200, data.customer])
